// src/components/Layout.js
import React, { useEffect, useState } from "react"
import { GlobalStyles } from "twin.macro"
// import { SEOContext } from "gatsby-plugin-wpgraphql-seo"
// import { useStaticQuery, graphql } from 'gatsby';

const Layout = ({ children, ...rest }) => {
  // const {
  //   wp: { seo },
  // } = useStaticQuery(graphql`
  //   query SiteInfoQuery {
  //     wp {
  //       seo {
  //         contentTypes {
  //           post {
  //             title
  //             schemaType
  //             metaRobotsNoindex
  //             metaDesc
  //           }
  //           page {
  //             metaDesc
  //             metaRobotsNoindex
  //             schemaType
  //             title
  //           }
  //         }
  //         webmaster {
  //           googleVerify
  //           yandexVerify
  //           msVerify
  //           baiduVerify
  //         }
  //         schema {
  //           companyName
  //           personName
  //           companyOrPerson
  //           wordpressSiteName
  //           siteUrl
  //           siteName
  //           inLanguage
  //           logo {
  //             sourceUrl
  //             mediaItemUrl
  //             altText
  //           }
  //         }
  //         social {
  //           facebook {
  //             url
  //             defaultImage {
  //               sourceUrl
  //               mediaItemUrl
  //             }
  //           }
  //           instagram {
  //             url
  //           }
  //           linkedIn {
  //             url
  //           }
  //           mySpace {
  //             url
  //           }
  //           pinterest {
  //             url
  //             metaTag
  //           }
  //           twitter {
  //             username
  //           }
  //           wikipedia {
  //             url
  //           }
  //           youTube {
  //             url
  //           }
  //         }
  //       }
  //     }
  //   }
  // `)

  return (
    // <SEOContext.Provider value={{ global: seo }}>
    <div {...rest}>
      <GlobalStyles />
      {children}
    </div>
    // </SEOContext.Provider>
  )
}

export default Layout
